<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>流量组</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/flowCrew' }">
          组员设置
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px"
        size="mini"
      >
        <el-form-item
          label="用户名称: "
          label-position="left"
          class="input"
          prop="name"
        >
          <el-input style="width: 200px" v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="用户ID: " label-position="left">
          <div>
            <div class="flex">
              <el-select
              v-model="searchType"
              slot="prepend"
              placeholder="选择类型"
              size="mini"
              @change="changeSearch"
            >
              <el-option label="微信昵称" :value="1"></el-option>
              <el-option label="UID" :value="2"></el-option>
            </el-select>
           

            <el-select
            ref="select"
            @hook:mounted="cancalReadOnly"
            @visible-change="cancalReadOnly"
             v-model="studentName"
             size="mini"
             clearable
             filterable
             remote
             reserve-keyword
             @change="selectUser"
             @clear="clearUser"
             placeholder="请输入关键词"
             :remote-method="searchStudent"
             :loading="loading"
           >
             <el-option
               style="height: 40px; line-height: 40px"
               v-for="item in users"
               :key="item.id"
               :label="item.name"
               :value="item.id"
             >
               <div
                 style="
                   display: flex;
                   justify-content: start;
                   align-items: center;
                   height: 40px;
                   line-height: 40px;
                 "
               >
                 <span style="margin-right: 10px"> {{ item.id }} </span>
                 <el-avatar size="small" :src="item.avatar_url"></el-avatar>
                 <span style="margin-left: 10px">{{ item.name }}</span>
               </div>
             </el-option>
           </el-select>
            </div>
          <div class="margin-t-sm" v-if="notData">暂无数据</div>
          <div
            v-if="JSON.stringify(selectCurrentUser) !== '{}'"
            style="
              margin-top: 12px;
              display: flex;
              align-items: center;
              padding: 10px;
              border-radius: 4px;
              margin: 4px;
            "
          >
            <el-avatar
              size="small"
              :src="selectCurrentUser.avatar_url"
            ></el-avatar>
            <span style="margin-left: 4px">{{ selectCurrentUser.name }}</span>
          </div>
        </div>
        </el-form-item>

        <el-form-item label="状态: " label-position="left" class="input">
          <el-radio v-model="form.status" :label="1">启用</el-radio>
          <el-radio v-model="form.status" :label="0">禁用</el-radio>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            @click="onSubmit"
          >
            保存
          </el-button>
          <el-button
            style="margin-left: 10px"
            size="small"
            @click="$router.back()"
          >
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Add",

  data() {
    return {
      searchType: 1,
      studentName: "",
      users: [],
      selectCurrentUser: {},
      loading: false,
      notData: false,
      form: {
        status: 1,
      },
      rules: {
        name: [
          { required: true, trigger: "change", message: "请输入用户名称" },
        ],
        userId: [
          { required: true, trigger: "change", message: "请输入用户ID" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("flow", ["addMember"]),
    ...mapActions("user", ["getUserList"]),
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const {select} = this.$refs;
          const input = select.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
        }
      });
    },
    changeSearch() {},
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
          this.form.userId = item.id;
        }
      });
      this.form.user_id = id;
    },
    clearUser() {},
    searchStudent(v) {
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
      } else {
        searchData.uid = v;
      }

      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addClass();
        }
      });
    },
    async addClass() {
      this.loading = true;
      const { res_info } = await this.addMember(this.form);
      this.loading = false;
      this.$router.back();
      if (res_info != "ok") return;
      this.$message.success("创建成功");
    },
  },
};
</script>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
